<template>
  <div class="towerListDetails-page">
    <!-- <div style="">{{$route.query.lineName}}塔杆信息</div> -->
    <div class="top-button">
      <div>
        <el-button class="return-button" @click="$router.back()"></el-button>
      </div>
      <div>
        <el-button v-if="$hasElement('tower-tab-add')" class="kr-normal-button" @click="addTowerBtn">新增塔杆</el-button>
        <el-button class="kr-normal-button" @click="downloadTempla">下载模板</el-button>
        <el-upload
          style="display: inline-block;margin: 0 1vh;"
          :action="config.baseURL + '/eprdms/line/uploadLine'"
          :headers="{ Authorization: Authorization }"
          :limit="3"
          :show-file-list="false"
          :on-progress="uploadProgress"
          :on-success="uploadSuccess"
          :on-error="uploadError"
          :before-upload="beforeUpload"
        >
          <el-button class="kr-normal-button" v-if="$hasElement('line-btn-upload')">批量上传</el-button>
        </el-upload>
        <el-button class="kr-normal-button" @click="downloadLineTable" v-if="$hasElement('line-btn-download')">线路表下载</el-button>
      </div>
    </div>
    <div class="bottom-table">
      <el-table :data="tableData" border style="width: 100%" height="80vh">
        <el-table-column prop="date" label="序号" align="center" width="100">
          <template slot-scope="scope">
            <Icon name="bottomArrow" />
            <span>{{ scope.row.towerIndex }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="towerName" label="名称" align="center"></el-table-column>
        <el-table-column prop="towerNumber" label="编号" align="center"></el-table-column>
        <el-table-column prop="longitude" label="经度" align="center"></el-table-column>
        <el-table-column prop="latitude" label="纬度" align="center"></el-table-column>
        <el-table-column prop="type" label="类型" align="center"></el-table-column>
        <el-table-column prop="circuit" label="回路类型" align="center"></el-table-column>
        <el-table-column prop="height" label="高度" align="center"></el-table-column>
        <el-table-column prop="practicalHeight" label="呼高" align="center"></el-table-column>
        <el-table-column prop="cornerDirection" label="转角方向" align="center"></el-table-column>
        <el-table-column prop="cornerNumber" label="转角度数" align="center"></el-table-column>
        <el-table-column prop="span" label="档距" align="center"></el-table-column>
        <el-table-column prop="remark" label="备注" align="center"></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="handleView(scope.row)" type="text" class="table-btn" title="查看"><i class="el-icon-view" style="color:#fff;font-size:1.5vh;"></i></el-button>
            <el-button @click="handleEdit(scope.row)" type="text" class="table-btn" title="编辑" v-if="$hasElement('tower-tab-update')"><i class="el-icon-edit" style="color:#fff;font-size:1.5vh;"></i></el-button>
            <el-button @click="delTowers(scope.row)" type="text" class="table-btn" title="删除" v-if="$hasElement('tower-tab-delete')"><i class="el-icon-delete" style="color:#fff;"></i></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pager-class">
      <!-- <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" :current-page.sync="pageNumber" @current-change="handlePageChange(pageNumber)"></el-pagination> -->
      <el-pagination @current-change="handlePageChange(pageNumber)" :current-page.sync="pageNumber" :page-size="pageSize" layout="total, prev, pager, next" :total="total"></el-pagination>
    </div>
    <!-- 新增塔杆弹出层-->
    <el-dialog class="my-dialog tower-dialog" :title="addTowerDialogTitle" :close-on-click-modal="false" :visible.sync="towerFormDialogVisible" @closed="clearValidate('towerForm')">
      <el-form :model="towerForm" :rules="towerFormRules" ref="towerForm" class="tower-form-class">
        <el-form-item label="塔杆序号" :label-width="formLabelWidth" prop="towerIndex">
          <el-input v-model.trim.number="towerForm.towerIndex" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="塔杆名称" :label-width="formLabelWidth" prop="towerName">
          <el-input v-model.trim="towerForm.towerName" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="塔杆编号" :label-width="formLabelWidth" prop="towerNumber">
          <el-input v-model.trim="towerForm.towerNumber" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="经度" :label-width="formLabelWidth" prop="longitude">
          <el-input v-model.trim="towerForm.longitude" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="纬度" :label-width="formLabelWidth" prop="latitude">
          <el-input v-model.trim="towerForm.latitude" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="塔杆高度" :label-width="formLabelWidth" prop="height">
          <el-input v-model.trim.number="towerForm.height" auto-complete="off" class="meter-unit"></el-input>
        </el-form-item>
        <el-form-item label="呼高" :label-width="formLabelWidth" prop="practicalHeight">
          <el-input v-model.trim.number="towerForm.practicalHeight" auto-complete="off" class="meter-unit"></el-input>
        </el-form-item>
        <el-form-item label="塔杆类型" :label-width="formLabelWidth" prop="type">
          <el-select v-model.trim="towerForm.type" placeholder="请选择杆塔类型" allow-create clearable filterable  style="width: 10.5vw">
            <el-option key="直线塔" label="直线塔" value="直线塔"></el-option>
            <el-option key="耐张塔" label="耐张塔" value="耐张塔"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="回路类型" :label-width="formLabelWidth" prop="circuit">
          <el-select v-model.trim="towerForm.circuit" placeholder="请选择杆塔类型" allow-create clearable filterable  style="width: 10.5vw" >
            <el-option key="双回路" label="双回路" value="双回路"></el-option>
            <el-option key="四回路" label="四回路" value="四回路"></el-option>
            <el-option key="六回路" label="六回路" value="六回路"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="投运时间" :label-width="formLabelWidth" prop="serviceBeginTime">
          <el-date-picker v-model="towerForm.serviceBeginTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择投运时间" style="width: 10.5vw" ></el-date-picker>
        </el-form-item>
        <el-form-item label="是否充电塔" :label-width="formLabelWidth" prop="isStation">
          <el-radio-group v-model="towerForm.isStation" style="margin-left: 1vw;" @change="groupChange('station')">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否气象塔" :label-width="formLabelWidth" prop="isWeather">
          <el-radio-group v-model="towerForm.isWeather" style="margin-left: 1vw;" @change="groupChange('weather')">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="运行状态" :label-width="formLabelWidth" prop="towerStatus">
          <el-radio-group v-model="towerForm.towerStatus" style="margin-left: 1vw; " @change="groupChange('status')">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="转角方向" :label-width="formLabelWidth" prop="cornerDirection">
          <el-input v-model.trim="towerForm.cornerDirection" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="转角度数" :label-width="formLabelWidth" prop="cornerNumber">
          <el-input v-model.trim="towerForm.cornerNumber" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="档距" :label-width="formLabelWidth" prop="span">
          <el-input v-model.trim.number="towerForm.span" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth" prop="remark" class="remark">
          <el-input v-model.trim="towerForm.remark" auto-complete="off" type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" stly="width: 20vw;" ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" v-if="isAddTower">
        <el-button class="cancel-button" @click="towerFormDialogVisible = false">
          取 消 新 增
        </el-button>
        <el-button class="save-button" @click="confirmAdd">提 交 新 增</el-button>
      </span>
      <span slot="footer" class="dialog-footer" v-else>
        <el-button class="cancel-button" @click="towerFormDialogVisible = false">
          取 消 修 改
        </el-button>
        <el-button class="save-button" @click="updateTowerInfo">提 交 修 改</el-button>
      </span>
    </el-dialog>
    <!-- 杆塔详情 -->
    <el-dialog class="my-dialog tower-detail-dialog" title="杆塔详情" :close-on-click-modal="false" :visible.sync="towerDescriptionDialogVisible">
      <el-descriptions class="my-description" :column="3" size="medium" border>
        <el-descriptions-item>
          <template slot="label">
            序号
          </template>
          {{ towerDetail.towerIndex }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            名称
          </template>
          {{ towerDetail.towerName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            编号
          </template>
          {{ towerDetail.towerNumber }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            经度
          </template>
          {{ towerDetail.longitude }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            纬度
          </template>
          {{ towerDetail.latitude }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            类型
          </template>
          {{ towerDetail.type }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            高度
          </template>
          {{ towerDetail.height }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            呼高
          </template>
          {{ towerDetail.practicalHeight }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            回路类型
          </template>
          {{ towerDetail.circuit }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            转角方向
          </template>
          {{ towerDetail.cornerDirection }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            转角度数
          </template>
          {{ towerDetail.cornerNumber }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            档距
          </template>
          {{ towerDetail.span }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            投运时间
          </template>
          {{ towerDetail.serviceBeginTime }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            是否充电塔
          </template>
          {{ towerDetail.isStation ? "是" : "否" }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            是否气象塔
          </template>
          {{ towerDetail.isWeather ? "是" : "否" }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            运行状态
          </template>
          {{ towerDetail.towerStatus ? "正常运行" : "禁用" }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            备注
          </template>
          {{ towerDetail.remark }}
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>
<script>
/* eslint-disable no-useless-escape */
import Icon from "@/components/Icon.vue";
import qs from "qs";
import config from "@/http/config.js";
import { showLoading, closeLoading } from "@/utils/js/Loading.js";
import { getqueryTowersList, getLinedownloadTemplate, getLinedownloadLine, gettowerType, postaddNewTower, postdeleteTower, postupdateTower } from "@/http/towerListDetailsApi.js";
export default {
  name: "towerListDetails",
  components: {
    Icon
  },
  data() {
    const checkTowerIndex = (rule, value, callback) => {
      console.log(typeof value);
      if(typeof value !== 'number') {
        callback(new Error('塔杆序号必须是数字'))
      } else {
        if(value <= 0 ) {
          callback(new Error("塔杆序号必须大于0"))
        }else{
          callback();
        }
      }
    };

    const checkTowerHeight = (rule, value, callback) => {
      if(value < 0 ) {
        callback(new Error("塔杆高度必须不能小于0"))
      }else{
        callback();
      }
    };

    return {
      config: config, //请求配置
      Authorization: sessionStorage.getItem("Authorization"),
      lineName: this.$route.query.lineName, //线路名
      lineId: this.$route.query.lineId, //线路ID
      total: 0,
      pageSize: 13,
      pageNumber: 1,
      tableData: [], //线路塔数据表格
      fileList: [],
      addTowerDialogTitle: "塔杆信息",
      towerFormDialogVisible: false,
      towerDescriptionDialogVisible: false,
      towerDetail: "",
      formLabelWidth: "5vw", //label宽度
      towerTypeList: [],
      isAddTower: true, //是否添加杆塔
      towerForm: {
        id: "",
        towerName: "",
        towerNumber: "",
        towerIndex: "",
        longitude: "",
        latitude: "",
        height: "",
        practicalHeight: "",
        type: "",
        isWeather: false,
        isStation: false,
        circuit: "",
        cornerDirection: "",
        cornerNumber: "",
        serviceBeginTime: "",
        towerStatus: true,
        span: "",
        remark: ""
      },
      towerFormRules: {
        towerName: [{ required: true, message: "请输入塔杆名", trigger: "blur" }],
        towerNumber: [{ required: true, message: "请输入塔杆编号", trigger: "blur" }],
        towerIndex: [
          { required: true, message: "请输入塔杆序号", trigger: "blur" },
          { validator: checkTowerIndex, trigger: "blur" }
        ],
        longitude: [
          { required: true, message: "请输入塔杆经度", trigger: "blur" },
          { pattern: /^[\-\+]?(0(\.\d{1,10})?|([1-9](\d)?)(\.\d{1,10})?|1[0-7]\d{1}(\.\d{1,10})?|180\.0{1,10})$/, message: "经度不能大于180", trigger: "blur" }
        ],
        latitude: [
          { required: true, message: "请输入塔杆纬度", trigger: "blur" },
          { pattern: /^[\-\+]?((0|([1-8]\d?))(\.\d{1,10})?|90(\.0{1,10})?)$/, message: "纬度不能大于90", trigger: "blur" }
        ],
        height: [
          { required: true, message: "请输入塔杆高度", trigger: "blur" },
          { validator: checkTowerHeight, trigger: "blur" }
        ],
        practicalHeight: [{ required: true, message: "请输入塔杆呼高", trigger: "blur" }],
        type: [{ required: true, message: "请选择塔杆类型", trigger: "blur" }],
        circuit: [{ required: true, message: "请选择回路类型", trigger: "blur" }],
        isWeather: [{ required: true, message: "请选择是否气象塔", trigger: "blur" }],
        isStation: [{ required: true, message: "请选择是否充电塔", trigger: "blur" }],
        serviceBeginTime: [{ required: true, message: "请选择投运时间", trigger: "blur" }]
      }
    };
  },
  mounted() {
    this.getTowerTableData();
    this.gettowerTypeList();
  },
  methods: {
    // addTowerBtn
    addTowerBtn() {
      this.towerFormDialogVisible = true;
    },
    beforeUpload(file) {
      console.log("上传之前调用", file);
      var fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      console.log(fileType, fileType != "xls" || fileType != "xlsx");
      if (fileType != "xls" && fileType != "xlsx") {
        this.$message.warning("批量上传只支持.xlsx");
        return false;
      } else {
        showLoading();
      }
    },
    uploadProgress(event, file, fileList) {
      console.log("上传时", event, file, fileList);
    },
    uploadSuccess(response, file, fileList) {
      console.log("上传成功", response, file, fileList);
      if(response.code=="000000") {
        this.$message.success("上传成功");
      }else {
        this.$message.error(response.data);
      }
     
      closeLoading();
      this.getTowerTableData();
    },
    uploadError(err) {
      console.log(err);
      closeLoading();
      this.$message({
        type: "error",
        message: err
      });
    },
    handlePageChange() {
      this.getTowerTableData();
    },
    // 编辑按钮
    handleEdit(row) {
      this.isAddTower = false;
      this.towerForm = JSON.parse(JSON.stringify(row));
      this.towerFormDialogVisible = true;
    },
    /**
     * @Author KR0132
     * @Date 2022/02/09 18:25:01
     * @Description 查看杆塔详情
     */
    handleView(tower) {
      this.towerDescriptionDialogVisible = true;
      this.towerDetail = tower;
    },
    // 关闭对话框时清除校验
    clearValidate(formName) {
      this.$refs[formName].clearValidate();
      this.isAddTower = true;
      this.towerForm = {
        towerNumber: "",
        towerIndex: "",
        longitude: "",
        latitude: "",
        height: "",
        type: "",
        conductorWireHammer: "",
        earthWireHammer: "",
        horizontalSpan: "",
        verticalSpan: "",
        practicalHeight: "",
        remake: "",
        isWeather: false,
        isStation: false,
        towerStatus: true,
      };
    },
    // 获取塔杆类型
    gettowerTypeList() {
      gettowerType().then(res => {
        if (res.code == "000000") {
          this.towerTypeList = res.data;
        }
      });
    },
    // 下载模板按钮
    downloadTempla() {
      getLinedownloadTemplate()
        .then(res => {
          this.getOutExcel("模板文件.xlsx", res);
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 下载线路表
    downloadLineTable() {
      getLinedownloadLine(qs.stringify({ lineId: this.lineId }))
        .then(res => {
          this.getOutExcel("线路表.xlsx", res);
          // hideLoading();
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 获取塔杆数据
    getTowerTableData() {
      let params = { lineId: this.lineId, pageNumber: this.pageNumber, pageSize: this.pageSize };
      getqueryTowersList(params).then(res => {
        if (res.rows != null) {
          this.tableData = res.rows;
          this.total = res.total;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },
    // 添加塔杆
    confirmAdd() {
      this.$refs.towerForm.validate(valid => {
        if (valid) {
          let params = this.towerForm;
          params.lineId = this.lineId;
          params.confirmToCover = false;
          params.lineName = this.lineName;
          postaddNewTower(params).then(res => {
            if (res.code == "000000") {
              this.$message.success(res.mesg);
              this.getTowerTableData();
              this.towerFormDialogVisible = false;
            } else {
              this.$message.error(res.data);
            }
          });
        }
      });
    },
    //删除塔杆
    delTowers(item) {
      let that = this;
      let params = {
        towerId: item.id
      };
      //删除提示窗
      this.$confirm("此操作将删除该塔杆信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false
      })
        .then(() => {
          postdeleteTower(params).then(res => {
            if (res.code == "000000") {
              this.$message.success(res.mesg);
              this.getTowerTableData();
            } else {
              this.$message.error(res.mesg);
            }
          });
        })
        .catch(() => {
          that.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    // 更新塔杆数据
    updateTowerInfo() {
      this.$refs.towerForm.validate(valid => {
        if (valid) {
          let params = this.towerForm;
          params.lineId = this.lineId;
          params.lineName = this.lineName;
          postupdateTower(params).then(res => {
            if (res.code === "000000") {
              this.$message.success(res.mesg);
              this.getTowerTableData();
              this.towerFormDialogVisible = false;
            } else {
              this.$message.error(res.mesg);
            }
          });
        }
      });
    },
    //  fileName : 文件名 res:二进制流
    getOutExcel(fileName, res) {
      let blob = new Blob([res], { type: "application/x-xls" });
      if (window.navigator.msSaveOrOpenBlob) {
        //兼容 IE & EDGE
        navigator.msSaveBlob(blob, fileName);
      } else {
        var link = document.createElement("a");
        // 兼容不同浏览器的URL对象
        const url = window.URL || window.webkitURL || window.moxURL;
        // 创建下载链接
        link.href = url.createObjectURL(blob);
        //命名下载名称
        link.download = fileName;
        //点击触发下载
        link.click();
        //下载完成进行释放
        url.revokeObjectURL(link.href);
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.towerListDetails-page {
  height: calc(100% - 5vh);
  padding: 0 2vw;
  color: #fff;
  .top-button {
    height: 5vh;
    padding-top: 2vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .kr-normal-button {
      padding: 1vh;
    }
  }
  .bottom-table {
    padding-top: 2vh;
  }
  .pager-class {
    display: flex;
    justify-content: center;
  }
  
  .tower-form-class {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .el-form-item {
      width: 48%;
      .meter-unit::after {
        content: "M";
        color: #ccc;
        position: absolute;
        top: 0;
        right: 0.5vw;
      }
    }
     .remark {
         width: 50vw;
        .el-form-item__content {
          width: 20vw;
        }
      }
  }
}
</style>
